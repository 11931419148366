























































import mixins from 'vue-typed-mixins'
import Navigation from '@/builder/sections/mixins/Navigation'
import AdditionalPagesMenu from '@/builder/sections/navbar/components/AdditionalPagesMenu.vue'
import LoggedUserMenu from '@/builder/sections/navbar/components/LoggedUserMenu.vue'

export default mixins(Navigation).extend({
  name: 'Navbar3',
  components: { LoggedUserMenu, AdditionalPagesMenu }
})
